<template>
  <div class="topTitle">
    <span class="title">{{title}}</span><br/>
    <span style="white-space:pre-wrap" class="subTitle">{{subTitle}}</span>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/scss/style.scss";

.topTitle {
  position: absolute;
  color: #fff;
  right: 0;
  left: 0;
  padding: 0 4%;
  bottom: 35%;
  @media #{$phone} {
    bottom: 40%;
  }
  .title {
    @include font("bigTitle");
  }
  .subTitle {
    @include font("medium");
  }
}
</style>

<script>
export default {
  name: "topTitle",
  props: {
    title: String,
    subTitle: String
  }
};
</script>
