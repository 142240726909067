<template>
  <div id="blog-view">
    <div class="title">{{openedArticle.title}}</div>
    <div class="divider" :title="openedArticle.type" 
    :class="{'red': openedArticle.type=='FP玩粉報', 'purple': openedArticle.type=='社群經營', 'blue': openedArticle.type=='數位廣告'}"></div>
    <div class="info">
      <div class="hashtag"
      :class="{'red': openedArticle.type=='FP玩粉報', 'purple': openedArticle.type=='社群經營', 'blue': openedArticle.type=='數位廣告'}">
        <span v-for="(hashtag, idx) in hashtags" :key="idx"> #{{hashtag}}</span>
      </div>
      <!--<div class="postdate">Posted {{openedArticle.postdate | blogDate}}</div>-->
    </div>
    <div class="popularCount">累計人氣：{{openedArticle.counts}}</div>
    <!-- id="article" -->
    <slot />
    <div class="share">
      <span @click="shareArticleToFb" class="btn">SHARE <i class="fab fa-facebook-square"></i></span>
      <div class="hashtag"
      :class="{'red': openedArticle.type=='FP玩粉報', 'purple': openedArticle.type=='社群經營', 'blue': openedArticle.type=='數位廣告'}">
        <span v-for="(hashtag, idx) in hashtags" :key="idx"> #{{hashtag}}</span>
      </div>
    </div>
    <hr>
    <div class="direction" :class="{'noPrev': !hasPrev}">
      <span v-if="hasPrev" class="btn" @click="articleChange(-1)"><i class="fas fa-arrow-left"></i> PREVIOUS</span>
      <span v-if="hasNext" class="btn" @click="articleChange(1)">NEXT <i class="fas fa-arrow-right"></i></span>
    </div>
    <hr>
  </div>
</template>

<script>
export default {
  props: {
    openedArticle: Object
  },
  data() {
    return {
      hashtags: []
    }
  },
  computed: {
    hasPrev() {
      return this.$parent.articleId != 0;
    },
    hasNext() {
      return Number(this.$parent.articleId) + 1 != this.$parent.articles.length;
    }
  },
  beforeMount() {
    this.hashtags = this.openedArticle.hashtag.split("#@#")
  },
  mounted() {
    this.$slots.default[0].elm.firstChild.style.display = "none";
  },
  methods: {
    articleChange(direction) {
      this.$parent.articleId = (Number(this.$parent.articleId) + direction).toString();
    },
    shareArticleToFb() {
      FB.ui({
        method: "share",
        href: window.location.href
      })
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

.hashtag {
  span {
    cursor: default;
    transition: .3s;
  }
  &.red {
    span:hover {color: $mainRed}
  }
  &.purple {
    span:hover {color: $mainPurple}
  }
  &.blue {
    span:hover {color: $mainBlue}
  }
}

#article {
  img {
    max-width: 100%;
  }
}

#blog-view {
  width: 702px;
  line-height: 2;
  iframe {
    width: 100%;
  }
  @media #{$tablet} {
    width: 90vw;
  }
  .title {
    text-align: left;
    @include font(small);
    white-space: pre-wrap;
    width: 100%;
    font-weight: bold;
  }
  .divider {
    width: 100%;
    border-style: solid;
    border-width: 0 0 3px 0;
    margin: 2% 0;
    &.red {
      border-color: $mainRed;
    }
    &.purple {
      border-color: $mainPurple;
    }
    &.blue {
      border-color: $mainBlue;
    }
  }
  .info {
    display: flex;
    justify-content: space-between;
  }
  .popularCount {
    margin-bottom: 4%;
    text-align: right;

  }
  .btn {
    cursor: pointer;
  }
  .share {
    position: relative;
    margin-top: 8%;
    display: flex;
    justify-content: center;
    .btn {
      transition: .3s;
      &:hover {
        color: #3b5998;
      }
    }
    .hashtag {
      position: absolute;
      right: 0;
    }
  }
  .direction {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 2% 0;
    &.noPrev {
      justify-content: flex-end;
    }
  }
}

</style>
