<template>
  <div id="blog">
    <transition name="opaFadeIn">
    <loading v-if="isLoading" />
    </transition>
    <transition name="opaFadeIn">
    <div id="back-to-top" @click="scrollToTop" v-if="showScrollTop">
      <i class="fas fa-angle-up"></i>
    </div>
    </transition>
    <transition name="opaFadeIn">
    <div id="category" v-if="articleShow && showSideCat">
      <div
        class="sideNav deafult"
        @click="chooseNav(1)"
      >ALL</div>
      <div
        class="sideNav red"
        @click="chooseNav(2)"
      >FP 玩粉報</div>
      <div
        class="sideNav purple"
        @click="chooseNav(3)"
      >社群經營</div>
      <div
        class="sideNav blue"
        @click="chooseNav(4)"
      >數位廣告</div>
    </div>
    </transition>
    <div class="return" v-if="articleShow" @click="articleShow=false">
      <i class="fas fa-arrow-left" style="margin-right:2px; transform:translateY(1px)"></i>
    </div>
    <header>
      <top-title title="WHAT'S NEWS" subTitle="回顧之前、肯定現在、挑戰未來"></top-title>
    </header>
    <div id="innerNav">
      <div
        class="navTopic deafult"
        @mouseover="navHov"
        @mouseout="navHovOut"
        @click="chooseNav(1)"
        style="color:#707070;"
      >ALL</div>
      <div
        class="navTopic red"
        @mouseover="navHov"
        @mouseout="navHovOut"
        @click="chooseNav(2)"
      >FP 玩粉報</div>
      <div
        class="navTopic purple"
        @mouseover="navHov"
        @mouseout="navHovOut"
        @click="chooseNav(3)"
      >社群經營</div>
      <div
        class="navTopic blue"
        @mouseover="navHov"
        @mouseout="navHovOut"
        @click="chooseNav(4)"
      >數位廣告</div>
      <hr id="navUnderLine">
    </div>
    <transition-group tag="div" name="trans" class="blog-preview-container" v-if="!articleShow">
      <template v-for="(article, idx) in articles">
        <blog-preview @click.native="chooseArticle(idx)"
          :key="idx"
          v-if="article.type.includes(currentType)"
          :indexImg="article.indexImg"
          :postdate="article.postdate"
          :title="article.title"
          :type="article.type"
          :subtitle="article.subtitle"
          :id="article.id"
          :counts="article.counts"
        />
      </template>
    </transition-group>
    <blog-view v-if="articleShow" :openedArticle="articles[articleId]" >
      <div id="article" v-html="articles[articleId].content"></div>
    </blog-view>
    <footer-view/>
  </div>
</template>

<script>
import loading from "@/components/loading.vue"
import topTitle from "@/components/topTitle.vue";
import blogPreview from "@/blogComponents/blogPreview.vue";
import blogView from "@/blogComponents/blogView.vue";
import footerView from "@/components/footerView.vue";

export default {
  components: {
    loading,
    topTitle,
    blogPreview,
    blogView,
    footerView
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  beforeMount() {
    var vm = this;
    $.ajax({
      type: "GET",
      url: process.env.VUE_APP_PHP + "ClientAPI/getAllBlog",
      data: {},
      dataType: "json",
      success: function(res) {
        // console.log(res);
        vm.articleData = res;
        vm.articles = vm.articleData;
        vm.handleQuery();
        setTimeout(() => {
          vm.isLoading = false
        }, 700);
      }
    });
  },
  mounted() {
    this.$parent.currentPage = "blog";
    this.$parent.isLoading = false;
    document.documentElement.classList.remove("noScrollbar");
    this.navHovOut();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
    document.documentElement.classList.add("noScrollbar");
  },
  data() {
    return {
      isLoading: true,
      isTablet: window.outerWidth <= 768 ? true : false,
      isMobile: window.outerWidth <= 480 ? true : false,
      showScrollTop: false,
      showSideCat: false,
      currentTopic: 1,
      articleShow: false,
      articleId: -1,
      articleData: [],
      articles: []
    };
  },
  computed: {
    currentType() {
      var currentType;
      var vm = this;
      switch (this.currentTopic) {
        case 2:
          currentType = "FP玩粉報";
          vm.chooseNav(2, true);
          vm.navHovOut();
          break;
        case 3:
          currentType = "社群經營";
          vm.chooseNav(3, true);
          vm.navHovOut();
          break;
        case 4:
          currentType = "數位廣告";
          vm.chooseNav(4, true);
          vm.navHovOut();
          break;
        default:
          currentType = "";
          vm.chooseNav(1, true);
          vm.navHovOut();
          break;
      }
      return currentType;
    }
  },
  methods: {
    chooseArticle(idx) {
      var vm = this;
      vm.articleId = idx;
      vm.articleShow = true;
      switch (vm.articles[idx].type) {
        case "FP玩粉報":
          vm.chooseNav(2, true);
          vm.navHovOut();
          break;
        case "社群經營":
          vm.chooseNav(3, true);
          vm.navHovOut();
          break;
        case "數位廣告":
          vm.chooseNav(4, true);
          vm.navHovOut();
          break;
        default:
          vm.chooseNav(1, true);
          vm.navHovOut();
          break;
      }
    },
    handleQuery() {
      var vm = this;
      if (this.$route.query.article) {
        let article = vm.$route.query.article;
        let openArticle;
        let hasMatch = false;
        vm.articles.forEach(el => {
          if (el.blogId == article) {
            vm.chooseArticle(el.id - 1);
            vm.articleShow = true;
            hasMatch = true;
            vm.scrollToTop();
          }
        })
        if (!hasMatch) vm.$router.replace({name: "blog"});
      }
    },
    handleScroll() {
      this.st = window.pageYOffset || document.documentElement.scrollTop;
      var hook = window.innerHeight;
      var vm = this;
      if (this.st > $("#innerNav").offset().top - 88) {
        vm.showScrollTop = true;
        vm.showSideCat = true;
      } else {
        vm.showScrollTop = false;
        vm.showSideCat = false;
      }
      if (this.st > this.lastScrollTop) {
        $(".return").css({ transform: "translateX(-85%)" });
      } else {
        $(".return").css({ transform: "translateX(0)" });
      }
      this.lastScrollTop = this.st <= 0 ? 0 : this.st;
    },
    navHov(e) {
      setTimeout(() => {
        $("#navUnderLine").width($(e.target).width() * 0.9);
        var left =
          $(e.target).offset().left -
          $("#innerNav").offset().left +
          ($(e.target).width() * (1 - 0.9)) / 2;
        $("#navUnderLine").css({ left: left + "px" });
      }, 0);
    },
    navHovOut() {
      setTimeout(() => {
        $("#navUnderLine").css({
          "left":
            $(".navTopic")[this.currentTopic - 1].offsetLeft +
            ($(".navTopic")[this.currentTopic - 1].clientWidth * (1 - 0.9)) / 2 +
            "px",
          "background-color": this.getColor()
        });
        $(".navTopic").css({ color: "#707070" });
        $(".navTopic")[this.currentTopic - 1].style.color = this.getColor();
        $("#navUnderLine").width(
          $(".navTopic")[this.currentTopic - 1].clientWidth * 0.9
        );
      }, 0);
      
    },
    handleResize() {
      this.navHovOut();
    },
    chooseNav(idx, boolean) {
      if (!boolean) {
        this.articleShow = false;
      }
      this.currentTopic = idx;
      $(".navTopic").css({ color: "#707070" });
      $(".navTopic")[this.currentTopic - 1].style.color = this.getColor();
      if(!boolean) {
        if(idx == 1) {
          this.articles = this.articleData;
        } else if(idx == 2) {
          this.articles = this.articleData.filter((item) => {
            return item.type == "FP玩粉報";
          })
        } else if(idx == 3) {
          this.articles = this.articleData.filter((item) => {
            return item.type == "社群經營";
          })
        } else if(idx == 4) {
          this.articles = this.articleData.filter((item) => {
            return item.type == "數位廣告";
          })
        }
      }
    },
    getColor() {
      var currentColor;
      switch (this.currentTopic) {
        case 2:
          currentColor = "#f12379";
          break;
        case 3:
          currentColor = "#9d4dce";
          break;
        case 4:
          currentColor = "#73cccf";
          break;
        default:
          currentColor = "#707070";
          break;
      }
      return currentColor;
    },
    scrollToTop() {
      $("html, body").animate({ scrollTop: $("#innerNav").offset().top - 88 }, 1000);
    },
  },
  watch: {
    articleId() {
      if(this.articleId != -1) {
        $("html, body").animate({ scrollTop: $("#innerNav").offset().top - 88 }, 0);
        this.$router.push({name: "blog", query: { article: this.articles[this.articleId].blogId}});
        //點了人氣+1
        $.ajax({
          type: "GET",
          url: process.env.VUE_APP_PHP + "ClientAPI/addBlogViews?sn=" + this.articles[this.articleId].blogId,
          data: {},
          dataType: "json"
        });
        this.articles[this.articleId].counts ++;
      }
    },
    articleShow() {
      $("html, body").animate({ scrollTop: $("#innerNav").offset().top - 88 }, 0);
      // this.$router.push({name: "blog", query: { article: this.articles[this.articleId].blogId}});
    },
    $route(val) {
      if(val.query.article == undefined) {
        this.articleShow = false;
        this.articleId = -1;
      } else {
        this.articleShow = true;
        //找到當前文章的陣列index 賦予新值觸發watch事件
        let tempArr = this.articles.find((item => {
          return  item.blogId == val.query.article
        }))
        this.articleId = this.articles.indexOf(tempArr)

      }
    }
  }
};
</script>


<style lang="scss" scope>
@import "@/assets/scss/style.scss";

header {
  background-image: url("../assets/image/blog/topBanner.jpg");
}

.trans-leave-active {
  position: absolute;
  z-index: -1;
}

.trans-enter-to,
.trans-leave {
  transform: scale(1);
  opacity: 1;
}

.trans-enter,
.trans-leave-to {
  transform: scale(0);
  opacity: 0;
}

#blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #707070;
  #back-to-top {
    position: fixed;
    cursor: pointer;
    left: 90%;
    bottom: 10%;
    @include font(large);
  }
  #category {
    position: fixed;
    text-align: left;
    border: 1px solid $mainPurple;
    border-radius: 5px;
    left: calc(50% + 351px + 12px);
    top: 20%;
    width: 125px;
    .sideNav {
      cursor: pointer;
      padding: 10% 15px;
      transition: .3s;
      &:hover {
        color: #fff;
        &.deafult {
          background-color: #707070 !important;
        }
        &.red {
          background-color: $mainRed !important;
        }
        &.purple {
          background-color: $mainPurple !important;
        }
        &.blue {
          background-color: $mainBlue !important;
        }
      }
    }
    @media only screen and (max-width : 1078px) {
      display: none;
    }
  }
  .return {
    cursor: pointer;
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateX(-85%);
    transition: 0.3s;
    background-color: #fff;
    padding: 5px 5px 5px 0;
    border-radius: 0 20px 20px 0;
    box-shadow: 0px 0px 8px 0px #707070;
    border-left-width: 0;
    z-index: 10;
    &:hover,
    &.active {
      transform: translate(0) !important;
    }
  }
  #innerNav {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 4% 0;
    align-items: flex-end;
    transform: translate(0) !important;
    .navTopic {
      cursor: pointer;
      margin: 0 10%;
      transition: 0.3s;
      @include font(small);
      font-weight: 300;
      white-space: nowrap;
      @media #{$phone} {
        margin: 0 4%;
      }
      &:hover {
        &.deafult {
          color: #707070 !important;
          & ~ #navUnderLine {
            background-color: #707070 !important;
          }
        }
        &.red {
          color: $mainRed !important;
          & ~ #navUnderLine {
            background-color: $mainRed !important;
          }
        }
        &.purple {
          color: $mainPurple !important;
          & ~ #navUnderLine {
            background-color: $mainPurple !important;
          }
        }
        &.blue {
          color: $mainBlue !important;
          & ~ #navUnderLine {
            background-color: $mainBlue !important;
          }
        }
        & ~ div {
          color: #707070 !important;
        }
      }
    }
    #navUnderLine {
      position: absolute;
      margin: 0;
      height: 1px;
      bottom: -10px;
      transition: 0.3s;
      @media #{$phone} {
        bottom: -5px;
      }
    }
  }
  .blog-preview-container {
    width: 80vw;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    .blog-preview {
      transition: .7s;
      transform-origin: 0 0;
      width: 28%;
      @media #{$tablet} {
        width: 47%;
      }
      @media #{$phone} {
        width: 100%;
      }
    }
  }
  #article {
    width: 100%;
    max-width: 1200px;
    text-align: initial;
  }
}
</style>
