<template>
  <div class="blog-preview" >
    <div class="wrapper">
      <img :src="indexImg">
    </div>
    <div class="contain">
      <!--<div class="time">Posted {{postdate | blogDate}}</div>-->
      <div class="popular"> 累計人氣：{{counts}}</div>
    </div>
    <div class="title">{{title}}</div>
    <div class="divider" :title="type" 
    :class="{'red': type=='FP玩粉報', 'purple': type=='社群經營', 'blue': type=='數位廣告'}"></div>
    <div class="subtitle">{{subtitle}}</div>
  </div>
</template>

<script>
export default {
  props: {
    indexImg: String,
    postdate: String,
    title: String,
    type: String,
    subtitle: String,
    id: Number | String,
    counts: Number | String
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.blog-preview {
  width: 28%;
  padding: 1%;
  @media #{$tablet} {
    width: 43%;
  }
  @media #{$phone} {
    width: 80%;
  }
  text-align: left;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
  img {
    width: 100%;
    transition: .5s;
  }
  div {
    margin: 2% 0;
  }
  .wrapper {
    overflow: hidden;
    margin: 0;
  }
  .contain {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .time,.popular {
    font-style: italic;
    font-weight: bold;
    @include font(smaller);
  }
  .title {
    font-weight: bold;
    color: #000;
    @include font(small);
    margin: 4% 0;
  }
  .divider {
    width: 20%;
    border-bottom: 1px;
    border: solid;
    &.red {
      border-color: $mainRed;
    }
    &.purple {
      border-color: $mainPurple;
    }
    &.blue {
      border-color: $mainBlue;
    }
  }
  .subtitle {
    @include font(smaller);
  }
}
</style>
