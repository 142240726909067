<template>
  <footer :class="{'short': isContact}">
    <router-link :to="{name: 'contact'}" class="contact" v-if="!isContact"><span>告訴我們您的需求</span></router-link>
    <div class="info">
      <div class="location">
        <div class="tel">Tel : +886 2 2971-2330 / Fax : +886 2 2971-2330</div>
        <div class="address">241 新北市三重區重陽路三段93號3樓</div>
      </div>
      <div class="email">service@fashioner.com.tw</div>
    </div>
    <div class="copyRight">copyright © 2014 Tsai Yi Technology All Rights Reserved</div>
  </footer>
</template>

<script>
export default {
  props:{
    isContact: Boolean
  }
}
</script>


<style lang="scss" scoped>
@import "../assets/scss/font-size.scss";

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 30vh;
  min-height: 120px;
  background-color: #999999;
  color: #fff;
  &.short {
    height: 15vh;
  }
  .contact {
    cursor: pointer;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
    border: #fff solid 1px;
    padding: 15px 0;
    @media #{$tablet} {
      width: 70%;
      margin-top: 20px;
      padding: 20px 0;
    }
    @media #{$phone} {
      margin-top: 15px;
      padding: 10px 0;
    }
    @include font(small);
    font-weight: 300;
    text-decoration: none;
    color: #fff;
    width: 33%;
    height: 50px;
    transition: 0.5s;
    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
  .info {
    flex-grow: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    @include font(smaller);
    @media #{$tablet} {
      flex-direction: column;
      justify-content: center;
    }
    .location {
      line-height: 18px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media #{$tablet} {
        align-items: center;
      }
    }
    .email {
      justify-self: flex-end;
    }
  }
  .copyRight {
    flex-grow: 1;
    @include font(smaller);
  }
}
</style>
